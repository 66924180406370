import imageUrlBuilder from "@sanity/image-url"

const builder = imageUrlBuilder({
  projectId: `q5bce0kb`,
  dataset: `production`,
})

function imageUrl(source) {
  return builder.image(source)
}

export default imageUrl
