import React from "react"
import imageUrl from "../../helpers/imageUrl"

const Author = ({ headline, image, text }) => {
  let authorImage = <div className="author__no-image"></div>

  if (image && image.asset) {
    let imageDimensions = image.asset.metadata.dimensions
    let imageWidth = imageDimensions.width
    let imageHeight = imageDimensions.height
    let cropPixels = { left: 0, top: 0 }
    let imageHotspot = { x: 0, y: 0 }
    if (image.hotspot) {
      imageWidth = Math.ceil(imageDimensions.width * image.hotspot.width)
      imageHeight = Math.ceil(imageDimensions.height * image.hotspot.height)
    }
    if (image.crop) {
      cropPixels = {
        left: Math.ceil(imageDimensions.width * image.crop.left),
        top: Math.ceil(imageDimensions.height * image.crop.top),
      }
    }
    if (image.hotspot) {
      imageHotspot = { x: image.hotspot.x, y: image.hotspot.y }
    }
    authorImage = (
      <div
        className="author__image"
        style={{
          backgroundImage: `url(${imageUrl(image.asset.url)
            .rect(cropPixels.left, cropPixels.top, imageWidth, imageHeight)
            .size(imageWidth, imageHeight)
            .focalPoint(imageHotspot.x, imageHotspot.y)
            .url()})`,
        }}
        alt={headline}
      ></div>
    )
  }
  return (
    <div className="author">
      <div className="author__image-wrap">{authorImage}</div>
      <div className="author__bio">
        <h3 className="author__title">{headline}</h3>
        <div className="author__name">{text}</div>
      </div>
    </div>
  )
}
export default Author
