import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import Author from "../../components/Author"
import { serializers } from "../../../helpers/blockContentSerializer.js"
import BlockContent from "@sanity/block-content-to-react"

class AuthorsPage extends React.Component {
  render() {
    let data = this.props.data
    let content = data.sanityAuthorsPage
    let authors = content.members.map(author => {
      return (
        <Author
          key={author._id}
          headline={author.title}
          image={author.image}
          text={author.name}
        />
      )
    })
    let authorsSection = (
      <div className="layout--margin-top-xxlarge">
        <h2>Styremedlemmer</h2>
        <div className="authors-wrapper">{authors}</div>
      </div>
    )
    return (
      <Layout article={true}>
        <div className="layout__mobile-container">
          <h2>{data.sanityAuthorsPage.pageTitle}</h2>
          <BlockContent blocks={content._rawText} serializers={serializers} />
        </div>
        {authorsSection ? authorsSection : null}
      </Layout>
    )
  }
}

export const query = graphql`
  query($slug: String) {
    sanityAuthorsPage(pageUrl: { current: { eq: $slug } }) {
      id
      pageTitle
      _rawText(resolveReferences: { maxDepth: 3 })
      members {
        title
        name
        image {
          asset {
            url
            metadata {
              dimensions {
                height
                width
              }
            }
          }
          crop {
            bottom
            left
            right
            top
          }
          hotspot {
            height
            width
            x
            y
          }
        }
        _id
      }
    }
  }
`
export default AuthorsPage
